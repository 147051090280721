<template>
  <div class="settingForm">
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="120" :style="{maxHeight:'calc(100vh - 100px)'}" style="overflow-y: auto">
      <FormItem prop="photoId" :label-width="0" style="width: 100%;text-align: center">
        <Tooltip content="建议尺寸：750X750像素">
          <ysUpload
              @on-success="bLFile"
              :limit="1"
              :headers="{token:token}"
              ref="upload"
              :format="['jpg','jpeg','png','gif']"
              :defaultList.sync="defaultList"
              action="/web/resource/UploadFile">
          </ysUpload>
        </Tooltip>
      </FormItem>
      <FormItem label="姓名" prop="iname">
        <Input v-model="form.iname" placeholder="姓名"></Input>
      </FormItem>
      <FormItem label="账号" prop="userName">
        <Input v-model="form.userName" placeholder="账号" :disabled="true"></Input>
      </FormItem>
      <FormItem label="手机号" prop="phone">
        <Input v-model="form.phone" placeholder="手机号"></Input>
      </FormItem>
      <FormItem label="身份证号码" prop="identityCode">
        <Input v-model="form.identityCode" placeholder="身份证号码"></Input>
      </FormItem>
      <FormItem label="职称" prop="position">
        <Input v-model="form.position" placeholder="职位"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">提交保存</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {userGetInfo} from "@/api/set";

export default {
  name: 'projectEdit',
  mixins: [edit],
  data() {
    return {
      ruleValidate: {},
      defaultList: [],
      form: {},
    }
  },
  mounted() {
    userGetInfo().then((res) => {
      this.form = res.data.expert
      this.form.userName = res.data.userName
      if (res.data?.expert.pictureId) this.defaultList.push({
        id: res.data.expert.pictureId,
        fileName: '',
        createdAt: '',
      })
      if (res.data?.mail) this.form.mail = res.data.mail
    })
  },
  methods: {
    // 上传图片回调，
    bLFile(data) {
      this.form.photoId = this.form.pictureId = data.length >= 1 ? data[0].id : '';
    },
    save() {
      this.ok('/expert/Edit')
    }
  }
}
</script>

<style lang="less" scoped>
.settingForm {
  padding: 30px;
  overflow: auto;
}
</style>
